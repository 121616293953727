// import ExtensionTwoToneIcon from '@mui/icons-material/ExtensionTwoTone';
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone";
import WysiwygTwoToneIcon from '@mui/icons-material/WysiwygTwoTone';
import TrendingUpTwoToneIcon from "@mui/icons-material/TrendingUpTwoTone";

const pagesSection = [
    {
        href: "/dashboard",
        icon: <DashboardTwoToneIcon />,
        title: "Dashboard",
    },
    {
        href: "/customers",
        icon: <PeopleAltTwoToneIcon />,
        title: "Customers",
    },
    // {
    //     href: "/templates",
    //     icon: <WysiwygTwoToneIcon/>,
    //     title: "Templates",
    // },
    // {
    //     href: "/integrations",
    //     icon: <ExtensionTwoToneIcon/>,
    //     title: "Integrations",
    // },
    {
        href: "/business-ac-req",
        icon: <TaskTwoToneIcon />,
        title: "Business Request",
    },
    {
        href: "/activity",
        icon: <TrendingUpTwoToneIcon />,
        title: "Reports",
        permission_code: 32768,
    },
    {
        href: "/templates",
        icon: <WysiwygTwoToneIcon />,
        title: "Templates",
        permission_code: 32768,
    },
];
const navItems = [
    {
        pages: pagesSection,
    },
];
export default navItems;
