import {createSlice} from "@reduxjs/toolkit";


export let STATUS = {
    IDLE:'idle',
    LOADING:'Loading',
    ERROR:'error'
}

const bsaReqSlice = createSlice({
    name: "account credential",
    initialState:{
        creadentails : [],
        reqDetails: {},
        status: STATUS.IDLE,
    },

    reducers:{
        SetCredentials: (state, action) => {
            state.creadentails = action.payload
        },
        SetReqCredentials: (state, action) => {
            state.reqDetails =  action.payload
        },
        ResetCredential: (state, action) =>{
            state.reqDetails = {};
        }
    }
});

export const { SetCredentials, SetReqCredentials, ResetCredential } = bsaReqSlice.actions;

export default bsaReqSlice.reducer;