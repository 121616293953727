import { createSlice } from "@reduxjs/toolkit";

const CustomersSlice = createSlice({
    name: "Customers",
    initialState:{
        CustomersList : { organisations: [], totalCount:0, searchText: "", page: 1},
        CustomersData : {},
        countries: [],
    },
    reducers: {
        SetCustomersData: (state, action) => {
            state.OrgData = action.payload;
        },
        SetCountries: (state, action) => {
            state.countries = action.payload;
        },
    }
});

export const {SetCountries} = CustomersSlice.actions;

export default CustomersSlice.reducer;




