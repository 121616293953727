import React from "react";
import async from "./components/Async";
import { Navigate } from "react-router-dom";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
// import AuthGuardProfileSetup from "./components/guards/AuthGuardProfileSetup";
// Auth components
import SignOut from "./pages/auth/SignOut";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

//login component
import Login from "./pages/login/index";

//privacy and terms of service

import PrivacyPage from "./pages/pages/privacy/index";
import TermsOfService from "./pages/pages/Terms of Service/index";

// Dashboard Saas components
const Analytics = async(() => import("./pages/dashboards/Analytics"));

// Organization components
const CustomerList = async(() => import("./pages/pages/customer/CustomerList"));
const CustomerDetails = async(() =>
    import("./pages/pages/customer/CustomerDetails")
);
const CustomerDetailsForm = async(() =>
    import("./pages/pages/customer/CustomerDetailsForm")
);

// TeamMembers components
const TeamMember = async(() => import("./pages/teammembers"));
const TeamMembersDetails = async(() =>
    import("./pages/pages/customer/TeamMembersDetails")
);

//Broadcast
const Broadcast = async(() => import("./pages/pages/broadcast/index"));
const AddBroadcast = async(() =>
    import("./pages/pages/broadcast/addBroadcast")
);

//Business Account
const BusinessAccountRequestes = async(() =>
    import("./pages/pages/businessaccount/RequestList")
);
//For whatsapp bsp
const RequestDetails = async(() =>
    import("./pages/pages/businessaccount/WhatsAppBSP/RequestDetails")
);
//For email
const EmailIntegrationForm = async(() =>
    import("./pages/pages/businessaccount/EmailIntegrations/EmailIntegrationForm")
);
const SMSIntegrationForm = async(() =>
    import("./pages/pages/businessaccount/SmsIntegrations/SmsForm")
);
const VoiceIntegrationForm = async(() =>
    import("./pages/pages/businessaccount/VoiceIntegrations/VoiceForm")
);

// Activity Report/Logs feed
const ActivityLogsFeed = async(() =>
    import("./pages/pages/activityFeed/ActivityLogsFeed")
);
const CustomersActivityLogsFeed = async(() =>
    import("./pages/pages/activityFeed/CustomersActivityLogsFeed")
);

//SMS Template
const SmsTemplate = async(() => import("./pages/smsTemplate/SmsTemplateList"));

const routes = [
    {
        path: "login",
        element: <PresentationLayout />,
        children: [
            {
                path: "",
                element: <Login />,
            },
        ],
    },
    {
        path: "auth",
        element: <AuthLayout />,
        children: [
            {
                path: "sign-in",
                element: <SignIn />,
            },
            {
                path: "sign-up",
                element: <SignUp />,
            },
            {
                path: "sign-out",
                element: (
                    <AuthGuard>
                        {" "}
                        <SignOut />{" "}
                    </AuthGuard>
                ),
            },
            {
                path: "reset-password",
                element: <ResetPassword />,
            },
            {
                path: "404",
                element: <Page404 />,
            },
            {
                path: "500",
                element: <Page500 />,
            },
        ],
    },
    {
        path: "/",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            //dashboard routes
            {
                path: "",
                element: <Navigate to="/dashboard" replace />,
            },
            {
                path: "dashboard",
                element: <Analytics />,
            },
            //Customer routes
            {
                path: "customers",
                element: <CustomerList />,
            },
            {
                path: "customer/create",
                element: <CustomerDetailsForm />,
            },
            {
                path: "customer/detail/:id",
                element: <CustomerDetails />,
            },
            //TeamMembers routes
            {
                path: "users",
                element: <TeamMember />,
            },
            {
                path: "user/detail/:id",
                element: <TeamMembersDetails />,
            },
            //Business Account Member
            {
                path: "business-ac-req",
                element: <BusinessAccountRequestes />,
            },
            {
                path: "business-ac-req/whatsapp_pinnacle_bsp/:id",
                element: <RequestDetails />,
            },
            {
                path: "business-ac-req/email/:id",
                element: <EmailIntegrationForm />,
            },
            {
                path: "business-ac-req/sms/:id",
                element: <SMSIntegrationForm />,
            },
            {
                path: "business-ac-req/voice/:id",
                element: <VoiceIntegrationForm />,
            },
            // Privacy and Terms Of Service
            {
                path: "privacy",
                element: <PrivacyPage />,
            },
            {
                path: "terms-service",
                element: <TermsOfService />,
            },

            //-------START-----Reports activity logs-----
            {
                path: "activity/logs",
                element: <ActivityLogsFeed />,
            },
            {
                path: "activity/customers-logs",
                element: <CustomersActivityLogsFeed />,
            },

            //-------END-------Reports activity logs-----

            //--------------SMS---template---------------

            {
                path: "templates",
                element: <SmsTemplate />,
            },
        ],
    },
    {
        path: "*",
        element: <AuthLayout />,
        children: [
            {
                path: "*",
                element: <Page404 />,
            },
        ],
    },
];
export default routes;
