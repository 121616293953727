import React from "react";
import styled from "@emotion/styled";
import { Divider, List, ListItemButton, ListItemText, Button } from "@mui/material";
import SidebarNavSection from "./SidebarNavSection";
import "../../vendor/perfect-scrollbar.css";
import DarkModeSwitch from "../navbar/DarkModeSwitch";
import NotificationsTwoToneIcon from '@mui/icons-material/NotificationsTwoTone';

import NavbarUserDropdown from "../navbar/NavbarUserDropdown";

const Item = styled(ListItemButton)`
  border-left: 3px solid transparent;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-inline: 16px;
  margin-block: 8px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  display: flex;
  flex-direction: column;

  svg {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 25px;
    width: 25px;
    height: 25px;
    opacity: 1;
  }

  &:hover {
    margin-inline: 16px;
    margin-block: 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:active {
    margin-inline: 16px;
    margin-block: 8px;
    border-radius: 8px;
    color: ${(props) => props.theme.palette.primary.main};
    background-color: rgba(112, 179, 255, 0.4);

    span {
      color: ${(props) => props.theme.palette.primary.main};;
    }

    
  }
`;

const ItemTT = styled(Button)`
  border-left: 3px solid transparent;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-inline: 16px;
  margin-block: 8px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  display: flex;
  flex-direction: column;

  svg {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 25px;
    width: 25px;
    height: 25px;
    opacity: 1;
  }

  &:hover {
    margin-inline: 16px;
    margin-block: 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:active {
    margin-inline: 16px;
    margin-block: 8px;
    border-radius: 8px;
    color: ${(props) => props.theme.palette.primary.main};
    background-color: rgba(112, 179, 255, 0.4);

    span {
      color: ${(props) => props.theme.palette.primary.main};;
    }

    
  }
`;

const Title = styled(ListItemText)`
  span {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: ${(props) => props.theme.typography.caption1.fontSize};
    padding: 0 ${(props) => props.theme.spacing(0)};
  }
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const SidebarNav = ({ items, openNotification }) => {
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up("md"));
  // const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;
  const ref = React.useRef(null);

  return (
    <List disablePadding className="d-flex flex-column justify-content-between" sx={{ height: "200%", width: "100%" }}>
      <Items>
        {items &&
          items.map((item, index) => (
            <SidebarNavSection
              component="div"
              key={index}
              pages={item.pages}
              title={item.title}
            />
          ))}
      </Items>
      <Items>
        <Divider />
        <DarkModeSwitch />
        <Item color="inherit" ref={ref} onClick={openNotification} >
          <NotificationsTwoToneIcon />
          <Title>Notifications</Title>
        </Item>
        <ItemTT>
          <NavbarUserDropdown />
        </ItemTT>
      </Items>
    </List>

  );
};

export default SidebarNav;
