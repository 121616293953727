import React, { useState } from "react";
import styled from "@emotion/styled";
import moment from "moment";
import {
  Box,
  Divider,
  Drawer as MUiDrawer,
  Link,
  Grid,
  LinearProgress as MuiLinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography as MuiTypography,
  Stack,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { darken } from "polished";
import {
  addMoreNotification,
  getTeamMemberNotifications,
  markAsReadNotification,
} from "../../../redux/slices/orgData";
import { GoPrimitiveDot } from "react-icons/go";
import { withTheme } from "@emotion/react";
import Axios from "axios";
import { appConfig } from "../../../config";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import { useNavigate } from "react-router-dom";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";

const Typography = styled(MuiTypography)(spacing);

const Drawer = styled(MUiDrawer)`
  width: 500px !important;
  height: 500px !important;
`;

const LinearProgress = styled(MuiLinearProgress)(spacing);
const CloseIconWrapper = styled.div`
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
`;

const EllipsisTitle = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 600;
  font-family: inherit;
  width: 95%;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const EllipsisDescription = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 400;
  font-family: inherit;
  width: 95%;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const NotificationsGrid = (props) => {
  const notifications = useSelector((store) => store.org.notifications);
  const notificationsCount = useSelector(
    (store) => store.org.notificationsCount
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(2);
  const [loading, setLoading] = useState(false);
  //const [loadMoreButton, setLodMoreButton] = useState(true);
  const navigate = useNavigate();

  const loadMore = async () => {
    setLoading(true);
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      const res = await Axios.get(
        `${appConfig.appNotificationServerUrl}notifications`,
        { params: { page_number: page, page_size: 10 } }
      );
      let data = await res.data;

      await dispatch(addMoreNotification(data));
      if (
        data &&
        data.notifications &&
        notifications.length === notificationsCount
      ) {
        //await setLodMoreButton(false);
        return;
      }
      await setPage((prevState) => prevState + 1);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleReadAllNotification = async (e) => {
    e.preventDefault();
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      await Axios.put(`${appConfig.appNotificationServerUrl}notifications`, {
        status: "read",
      });
      await dispatch(getTeamMemberNotifications());
    } catch (e) {
      console.log(e);
    }
  };

  const handleReadNotification = async (e, notification) => {
    e.preventDefault();
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      await Axios.put(
        `${appConfig.appNotificationServerUrl}notifications/${notification._id}`,
        { status: "read" }
      );
      await dispatch(markAsReadNotification(notification._id));
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteNotification = async (e, notification) => {
    e.preventDefault();
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      await Axios.put(
        `${appConfig.appNotificationServerUrl}notifications/${notification._id}`,
        { status: "delete" }
      );
      await dispatch(getTeamMemberNotifications());
    } catch (e) {
      console.log(e);
    }
  };

  const loadNotificationIcon = (type) => {
    switch (type) {
      case "campaign":
        return (
          <Box style={{ marginRight: "1rem", fontSize: "40px" }}>
            {" "}
            <InfoOutlinedIcon
              sx={{ fontSize: "30px", color: "rgba(0, 0, 0, 0.6)" }}
            />{" "}
          </Box>
        );
      case "subscription":
        return (
          <Box style={{ marginRight: "1rem", fontSize: "40px" }}>
            {" "}
            <CardMembershipOutlinedIcon
              sx={{ fontSize: "30px", color: "rgba(0, 0, 0, 0.6)" }}
            />{" "}
          </Box>
        );
      case "wallet":
        return (
          <Box style={{ marginRight: "1rem", fontSize: "40px" }}>
            {" "}
            <AccountBalanceWalletOutlinedIcon
              sx={{ fontSize: "30px", color: "rgba(0, 0, 0, 0.6)" }}
            />{" "}
          </Box>
        );
      case "information":
        return (
          <Box style={{ marginRight: "1rem", fontSize: "40px" }}>
            {" "}
            <InfoOutlinedIcon
              sx={{ fontSize: "30px", color: "rgba(0, 0, 0, 0.6)" }}
            />{" "}
          </Box>
        );
      case "addUser":
        return (
          <Box style={{ marginRight: "1rem", fontSize: "40px" }}>
            {" "}
            <PersonOutlinedIcon
              sx={{ fontSize: "30px", color: "rgba(0, 0, 0, 0.6)" }}
            />{" "}
          </Box>
        );
      case "businessUnit":
        return (
          <Box style={{ marginRight: "1rem", fontSize: "40px" }}>
            {" "}
            <ContactMailOutlinedIcon
              sx={{ fontSize: "30px", color: "rgba(0, 0, 0, 0.6)" }}
            />{" "}
          </Box>
        );
      default:
        return (
          <Box style={{ marginRight: "1rem", fontSize: "40px" }}>
            {" "}
            <InfoOutlinedIcon
              sx={{ fontSize: "30px", color: "rgba(0, 0, 0, 0.6)" }}
            />{" "}
          </Box>
        );
    }
  };

  const handleNavigate = (e, notification) => {
    switch (notification.type) {
      case "addUser":
        props.openNotification();
        if (notification.status === "active") {
          handleReadNotification(e, notification);
        }
        // navigate(`/users`);
        return null;
      case "businessUnit":
        props.openNotification();
        if (notification.status === "active") {
          handleReadNotification(e, notification);
        }
        // navigate(`/settings/businessUnits/${notification.default_businessunit_id}`);
        return null;
      default:
        props.openNotification();
        if (notification.status === "active") {
          handleReadNotification(e, notification);
        }
        return null;
    }
  };

  return (
    <Box sx={{ height: " calc(97vh - 58px) !important", overflowY: "auto" }}>
      <Box pt={3} display="flex" justifyContent="space-between">
        <CloseIconWrapper
          onClick={() => dispatch(getTeamMemberNotifications())}
        >
          <RefreshTwoToneIcon style={{ fontSize: "2rem" }} />
        </CloseIconWrapper>

        <Stack
          sx={{ cursor: "pointer" }}
          onClick={(e) => handleReadAllNotification(e)}
        >
          <Typography variant="subtitle1" color="primary" component={Link}>
            Mark all as read
          </Typography>
        </Stack>
      </Box>

      <List pt={0}>
        {notifications.map((notification, index) => {
          let iconColor =
            notification && notification?.status === "read" ? "" : "primary";
          return (
            <Box key={index} sx={{ height: "100%", display: "flex" }}>
              <ListItem divider sx={{ width: "100%", paddingX: "0" }}>
                {loadNotificationIcon(notification.type)}

                {/*<ListItemText primary={notification.title} secondary={moment(notification.createdAt).format("MMM DD, hh:mma")} />*/}
                <Stack
                  onClick={(e) => handleNavigate(e, notification)}
                  sx={{ width: "18rem", cursor: "pointer" }}
                >
                  <EllipsisTitle
                    sx={
                      notification?.status === "active"
                        ? { color: "inherit" }
                        : { color: "rgba(0, 0, 0, 0.6)" }
                    }
                  >
                    {notification.title}
                  </EllipsisTitle>
                  <EllipsisDescription
                    sx={
                      notification?.status === "active"
                        ? { color: "inherit" }
                        : { color: "rgba(0, 0, 0, 0.6)" }
                    }
                  >
                    {notification.description}
                  </EllipsisDescription>
                  <Typography
                    component="h6"
                    variant="body2"
                    color="textSecondary"
                  >
                    {moment(notification.createdAt).format("MMM DD, hh:mma")}
                  </Typography>
                </Stack>
              </ListItem>
              <Stack
                sx={{ height: "100%", marginTop: "10px" }}
                alignItems="center"
                justifyContent="space-between"
                spacing={5}
              >
                <Tooltip title="Clear" placement="left-end" arrow>
                  <Box
                    onClick={(e) => handleDeleteNotification(e, notification)}
                    sx={{ cursor: "pointer" }}
                    className={`icon-close icon-color-`}
                    style={{ fontSize: "15px" }}
                  />
                </Tooltip>

                <Tooltip
                  title={iconColor ? "Mark as read" : ""}
                  placement="left-end"
                  arrow
                >
                  <Box
                    sx={iconColor && { cursor: "pointer" }}
                    onClick={(e) => handleReadNotification(e, notification)}
                    className={`icon-dot icon-color-${iconColor}`}
                    style={{ fontSize: "16px" }}
                  />
                </Tooltip>
              </Stack>
            </Box>
          );
        })}
        {notificationsCount > 10 &&
          notifications.length !== notificationsCount && (
            <Box p={1} display="flex" justifyContent="center">
              {loading && <CircularProgress />}
              {!loading && (
                <Button size="small" onClick={loadMore}>
                  Show more
                </Button>
              )}
            </Box>
          )}
      </List>
    </Box>
  );
};

function NotificationsReport({ openNotification, isOpen, props }) {
  const { t, ready } = useTranslation(["notificationDropdown", "global"]);
  const LOADING = useSelector((store) => store.org.status);
  const notificationsCount = useSelector(
    (store) => store.org.notificationsCount
  );
  if (!ready) return <Loader />;
  return (
    <React.Fragment>
      <Drawer anchor="right" open={isOpen} onClose={openNotification}>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          sx={{ width: "400px" }}
        >
          <Grid container alignItems="center" pl={5} pr={5} py={3} my={2}>
            <Grid item>
              <CloseIconWrapper onClick={openNotification} my={3} p={3}>
                <span
                  className="icon-close"
                  style={{ fontSize: "22px" }}
                ></span>
              </CloseIconWrapper>
            </Grid>

            <Grid item xs={10} alignItems="center" id="notification">
              <Typography variant="h3" align="center">
                {t("notifications", { ns: "global" })}
              </Typography>
            </Grid>
          </Grid>

          <Divider style={{ width: "100%" }} />

          {LOADING === "Loading" && <LinearProgress />}
          <Grid item xs={12} pl={5} pr={5}>
            {LOADING === "error" && (
              <Typography
                variant="subtitle2"
                color="primary"
                mt={5}
                align="center"
              >
                {t("somethingWentWrong", { ns: "global" })}
              </Typography>
            )}
            {LOADING === "idle" &&
              (notificationsCount > 0 ? (
                <NotificationsGrid
                  propsTheme={props}
                  openNotification={openNotification}
                />
              ) : (
                <Typography
                  variant="subtitle2"
                  color="primary"
                  mt={5}
                  align="center"
                >
                  {t("noNotifications.")}
                </Typography>
              ))}
          </Grid>
        </Grid>
      </Drawer>
    </React.Fragment>
  );
}

export default withTheme(NotificationsReport);
