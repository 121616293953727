import {createSlice} from "@reduxjs/toolkit";

const accDetailsArray =[
    {
        id: '04353454354310',
        businessLogo: '',
        businessName: 'Thinkonic Software Pvt. Ltd.',
        businessDesc: 'Thinkonic is a technology company based in India that provides consulting services in application development, cloud computing and dev ops.',
        businessPhoneNumber: '+919172753699',
    },
    {
        id: '1543454354310',
        businessLogo: '',
        businessName: 'Thinkonic Software Pvt. Ltd.',
        businessDesc: 'Thinkonic is a technology company based in India that provides consulting services in application development, cloud computing and dev ops.',
        businessPhoneNumber: '+919172753699',
    },
    {
        id: '0534221344210',
        businessLogo: '',
        businessName: 'Thinkonic Software Pvt. Ltd.',
        businessDesc: 'Thinkonic is a technology company based in India that provides consulting services in application development, cloud computing and dev ops.',
        businessPhoneNumber: '+919172753699',
    },
    {
        id: '01042242231312',
        businessLogo: '',
        businessName: 'Thinkonic Software Pvt. Ltd.',
        businessDesc: 'Thinkonic is a technology company based in India that provides consulting services in application development, cloud computing and dev ops.',
        businessPhoneNumber: '+919172753699',
    },
    {
        id: '0242342423410',
        businessLogo: '',
        businessName: 'Thinkonic Software Pvt. Ltd.',
        businessDesc: 'Thinkonic is a technology company based in India that provides consulting services in application development, cloud computing and dev ops.',
        businessPhoneNumber: '+919172753699',
    },
]

export let STATUS = {
    IDLE:'idle',
    LOADING:'Loading',
    ERROR:'error'
}

const bsaReqSlice = createSlice({
    name: "account credential",
    initialState:{
        acDetails : accDetailsArray,
        bsaDetails: {},
        status: STATUS.IDLE,
    },

    reducers:{
        SetAcDetails: (state, action) => {
            state.bsaDetails = action.payload
        },
        SetAcReqDetails: (state, action) => {
            state.bsaDetails = action.payload;
        },
        ResetDetails: (state, action) =>{
            state.bsaDetails = {};
        }
    }
});

export const { SetAcDetails, SetAcReqDetails, ResetDetails } = bsaReqSlice.actions;

export default bsaReqSlice.reducer;