import { createSlice } from "@reduxjs/toolkit";

const botListSlice = createSlice({
    name: "bots list",
    initialState:{
        botList : { bots: [], totalCount:0},
        importLogs : {},
        errorRecords : {records:[], count:0}
    },

    reducers:{
        SetBotList: (state, action) => {
            state.botList.totalCount = action.payload.totalCount;
            state.botList.bots = action.payload.bots;
        },
        ResetBotList : (state) => {
            state.bots = {};
            state.importLogs = {};
            state.errorRecords = {records:[], count:0};
        }
    }
});

export const { SetBotList } = botListSlice.actions;

export default botListSlice.reducer;