import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Axios from 'axios';
import {appConfig} from "../../config";

export let STATUS = {
    IDLE: 'idle',
    LOADING: 'Loading',
    ERROR: 'error'
}

const orgDataSlice = createSlice({
    name: 'org',
    initialState: {
        data: [],
        LoggedInTeamMember: {},
        notifications: [],
        notificationsCount: 0,
        totalActiveCount: 0,
        status: STATUS.IDLE
    },
    reducers: {
        SetOrgList: (state, action) => {
            state.data = action.payload;
        },
        SetLoggedInTeamMember: (state, action) => {
            state.LoggedInTeamMember = action.payload;
        },
        addMoreNotification: (state, action) => {
            let data = state.notifications;
            data.push(...action.payload.notifications);
            state.notifications = data;
            state.notificationsCount = action.payload.totalCount;
        },
        markAsReadNotification: (state, action) => {
            let foundIndex = state.notifications.findIndex((notification) => notification._id === action.payload);
            state.notifications[foundIndex].status = 'read';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTeamMemberNotifications.pending, (state, action) => {
                state.status = STATUS.LOADING;
            })
            .addCase(getTeamMemberNotifications.fulfilled, (state, action) => {
                state.notifications = action.payload.notifications;
                state.totalActiveCount = action.payload.totalActiveCount;
                state.notificationsCount = action.payload.totalCount;
                state.status = STATUS.IDLE;
            })
            .addCase(getTeamMemberNotifications.rejected, (state) => {
                state.status = STATUS.ERROR;
            })
    },
})

export const {SetOrgList, addMoreNotification, SetLoggedInTeamMember, markAsReadNotification} = orgDataSlice.actions;

export default orgDataSlice.reducer;

//Get all notifications
export const getTeamMemberNotifications = createAsyncThunk('notifications', async () => {
    const accessToken = window.localStorage.getItem("accessToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const res = await Axios.get(`${appConfig.appNotificationServerUrl}notifications`,
    );
    let data = await res.data;
    return data;
});