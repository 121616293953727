import * as React from "react";
import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";
import {
    Menu as MuiMenu,
    IconButton as MuiIconButton, Grid, Avatar, ListItemIcon, ListItemButton, List, ListItemText, Typography,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import {useTranslation} from "react-i18next";
import {useRef, useState} from "react";
import Loader from "../Loader";


const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
    margin: 0.5px;
  }
  &:hover {
    background-color: transparent;
  }
 
`;

const Ellipsis = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10vw;
  white-space: nowrap;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  font-family: inherit;
`;


const AvatarText = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.h5.fontWeight};
  display: inline-block;
`;

const Menu = styled(MuiMenu)`
  .MuiMenu-list {
    padding: 0;
    border: 1px solid #dee2e6;
    border-radius: 16px;
    box-shadow: 5px 4px 4px 2px rgb(0 0 0 / 20%) !important;
  }
`;

function NavbarUserDropdown() {
    const {t,ready} = useTranslation('navbar')
    const navigate = useNavigate();
    const {signOut, user} = useAuth();
    const profile = document.getElementById('profile');
    const userMenu = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const openMenu = () => {
        setIsMenuOpen(true);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleProfile = () => {
        navigate('/profile')
        setIsMenuOpen(false);
    }

    const handleSignOut = async () => {
        await signOut();
    };

    if (!ready) return <Loader/>

    return (
        <React.Fragment>
            <Grid className="d-flex align-items-center" id="profile">
                <IconButton
                    aria-owns={isMenuOpen ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={openMenu}
                    onMouseLeave={closeMenu}
                    ref={userMenu}
                    color="inherit"
                    size="large"
                    sx={{borderRadius: '5px'}}
                >
                    {!!user &&
                        <Avatar alt={user.name} src={user.avatars}/>}
                    {!user && <Avatar alt="User" src="User"/>}
                </IconButton>
            </Grid>
            <Menu
                id="menu-appbar"
                anchorEl={userMenu.current}
                open={isMenuOpen}
                onClose={closeMenu}
                PaperProps={{
                    style: {
                        pointerEvents: 'auto',
                        boxShadow: 'rgb(0 0 0 / 5%) 5px 4px 4px 2px',
                        borderRadius: "16px"
                    }
                }}
                MenuListProps={{onMouseEnter: openMenu, onMouseLeave: closeMenu}}
                sx={{left: profile ? 8 * profile.offsetLeft : 0, pointerEvents: 'none'}}
            >
                <List sx={{ padding: 0 }}>
                    <ListItemButton className='square border-bottom' dense>
                        <ListItemIcon>
                            {!!user &&
                                <Avatar alt={user.name} src={user.avatars}/>}
                            {!user && <Avatar alt="User" src="/static/img/avatars/avatar-1.jpg"/>}
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={user && (<AvatarText variant='subtitle1'
                                lineHeight={1.3}><Ellipsis>{user.name}</Ellipsis></AvatarText>)}
                            secondary={user && (<Typography lineHeight={1.3} className="text-gray-500"
                                                            ><Ellipsis>{user.email || 'Admin'}</Ellipsis></Typography>)}
                        />
                    </ListItemButton>
                    <ListItemButton onClick={handleProfile} className='pt-2' dense>
                        <ListItemText
                            disableTypography
                            primary={<Typography variant="body1">{t("account", {ns: 'global'})}</Typography>}
                        />
                    </ListItemButton>
                    <ListItemButton onClick={handleSignOut} className='py-2 border-top' dense>
                        <ListItemText
                            disableTypography
                            primary={<Typography variant="body1">Sign Out</Typography>}
                        />
                    </ListItemButton>
                </List>
            </Menu>
        </React.Fragment>
    );
}

export default NavbarUserDropdown;
