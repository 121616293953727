import React, { useState } from 'react';
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
    Box,
    Grid,
    Card, Backdrop,
    Typography as MuiTypography, CircularProgress,
    Paper,
} from '@mui/material';
import Login from './components/Login'
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

const Typography = styled(MuiTypography)(spacing);


export default function LoginPage() {
    const theme = useTheme();
    const { ready } = useTranslation('global');
    const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
    const [showBackDrop, setShowBackDrop] = useState(false);

    if (!ready && !window.location.hash) return <Loader />;

    return (
        <Grid container>
            <Backdrop open={showBackDrop} sx={{ color: '#2052c2', zIndex: '1000001' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container sx={{
                backgroundImage: 'linear-gradient(skyblue,white,pink)',
                height: '100vh'
            }}>
                {isLgUp && <Grid item md={6} sx={{ paddingX: '5rem', paddingTop: '4rem' }}>
                    <Box component="img" src={'../static/img/login/logo1.svg'} height={'60px'}
                        width={'285px'} />

                    <Box pt={15}>
                        <Typography variant="h1" color="#1180FF">
                            Admin Portal
                        </Typography>
                    </Box>
                </Grid>}

                <Grid item md={isLgUp ? 6 : 12} xs={12} display="flex" alignItems="center" justifyContent="center" sx={{
                    backgroundColor: '#fff',
                    borderRadius: isLgUp ? '2rem 0 0 2rem' : '0'
                }}>
                    <Paper>
                        <Box display="flex" justifyContent="center">
                            <Card sx={{ height: '8.75rem', width: '8.75rem' }}>
                                <img alt="1SPOC Logo" src={"/static/img/logo-dark.svg"} className="m-4" />
                            </Card>
                        </Box>
                        <Login handleBackdrop={(value) => setShowBackDrop(value)} />
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}
