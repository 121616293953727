import * as React from 'react';
import { useEffect } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
    Grid,
    Typography as MuiTypography,
    Divider,
} from '@mui/material';
import { Auth0Lock } from 'auth0-lock';
import { auth0Config } from "../../../config";
import "./auth.css";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import { setSession } from "../../../utils/jwt";


const lock = new Auth0Lock(auth0Config.clientId, auth0Config.domain, {
    auth: {
        redirect: true,
        responseType: 'token id_token',
        redirectUrl: auth0Config.redirectUrl,
        params: { scope: 'openid email' }
    },
    container: 'lock-login-container',
    theme: {
        primaryColor: '#1180FF'
    },
    languageDictionary: {
        loginSubmitLabel: "Login",
        emailInputPlaceholder: "Enter email",
        passwordInputPlaceholder: 'Enter password',
        forgotPasswordTitle: 'Reset Password',
        forgotPasswordAction: 'Forgot Password?'
    },
    allowedConnections: ['Username-Password-Authentication'],
    autoFocus: true,
    allowSignUp: false,
    allowShowPassword: true,
    rememberLastLogin: false,
    allowForgotPassword: false,
    avatar: null,
    closable: false,
    autoParseHash: false
});


const Typography = styled(MuiTypography)(spacing);

export default function Login(props) {
    const { t } = useTranslation('login');
    const { signIn, signOut } = useAuth();

    const parseHash = (hash) => {
        hash = hash.replace('#', '');
        hash = hash.split('&');
        let authResult = {};

        for (let value of hash) {
            value = value.split("=");
            authResult[value[0]] = value[1];
        }

        return authResult
    }

    const authLogin = async (token) => {
        props.handleBackdrop(true);
        await setSession(token);
        await signIn();
        props.handleBackdrop(false);
    }

    useEffect(() => {
        lock.show();
        if (window.location.hash) {
            let authResult = parseHash(window.location.hash);

            if (authResult.error) {
                signOut();
                return;
            }

            if (authResult.id_token) {
                authLogin(authResult.id_token);
            }
        }

        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            authLogin(accessToken);
            props.handleBackdrop(true);
        } else {
            signOut();
        }

        return () => {
            lock.hide();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Helmet title="Login" />
            <Grid className={"droid"} container p={10}>
                <Grid className={"balls"} item sm={12} xs={12} mb={4}>
                    <Typography variant='h2' align='center' pt={3}>Login</Typography>
                    <Typography variant='body1' align='center' py={2}>{t("pleaseEnterEmail")} </Typography>
                    <Divider mt={4} pb={6} />
                    <div id="lock-login-container">
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

