import { createSlice } from "@reduxjs/toolkit";

const organizationListSlice = createSlice({
    name: "Customers",
    initialState:{
        OrganizationList : { organisations: [], totalCount:0, searchText: "", page: 1},
        OrgData : {},
        importLogs : {},
        errorRecords : {records:[], count:0}
    },
    reducers: {
        SetOrganizationList: (state, action) => {
            state.OrganizationList.totalCount = action.payload.totalCount;
            state.OrganizationList.organisations = action.payload.organisations;
        },
        SetOrgData: (state, action) => {
            state.OrgData = action.payload;
        },
        updateOrgSearchTextPage: (state, action) => {
            state.OrganizationList.searchText = action.payload.searchText;
            state.OrganizationList.page = action.payload.page;
        },
        DeleteOrgData: (state, action) => {
            state.OrganizationList.organisations = state.OrganizationList.organisations.filter(org => org._id !== action.payload);
            state.OrgData = {};
        },
        SetImportLogData: (state, action) => {
            state.importLogs = action.payload;
        },
        SetErrorRecords: (state, action) => {
            state.errorRecords = action.payload;
        },
        ResetState: (state) =>{
            state.OrgData = {};
            state.importLogs = {};
            state.errorRecords = {records:[], count:0};
            state.OrganizationList ={ organisations: [], totalCount:0, searchText: "", page: 1}
        }
    }
});

export const { SetOrganizationList, SetOrgData,updateOrgSearchTextPage, DeleteOrgData, ResetState} = organizationListSlice.actions;

export default organizationListSlice.reducer;




