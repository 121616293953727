import React, { useEffect } from "react";
import useAuth from '../../hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";


function SignIn() {
  const { signIn, customSignIn } = useAuth();
  const navigate = useNavigate();
  const fetchBots = async () => {
    try {
      navigate('/dashboard/analytics');
    } catch (error) {
      console.log(error.message);
      navigate('/auth/sign-out');
    }
  }

  useEffect(() => {
    (async function () {
      let url = window.location;
      let access_token = new URLSearchParams(url.search).get('code');
      if (access_token) {
        await customSignIn(url);
        await fetchBots();
      } else {
        await signIn();
      }
    })();
    // eslint-disable-next-line
  }, [])
  return (
    <React.Fragment>
      <CircularProgress m={2} />
    </React.Fragment>
  );
}

export default SignIn;
