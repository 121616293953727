import React, {Fragment} from "react";
import {Helmet} from "react-helmet-async";
import {Card, Typography} from "@mui/material";
import Header from "../components/Header";
import {useTranslation} from "react-i18next";
import Loader from "../../../components/Loader";


const TermsOfService = () => {
    const {t, ready} = useTranslation("termsOfService");

    if (!ready) return <Loader/>;

    return (
        <Fragment>
            <Helmet title="Terms Of Service"/>
            <Header heading={t("termsAndCondition")}/>
            <Card sx={{marginTop: '1rem', padding: '2.5rem'}}>

                <Typography variant="h5" mb={5}>{t("welcomeMessage")}</Typography>

                <Typography pb={1}>{t("introductionPara1")}</Typography>

                <Typography pb={1}>{t("introductionPara2")}</Typography>

                <Typography>{t("introductionPara3")}</Typography>

                <Typography variant="h5" fontWeight="550" my={5}>{t("cookies")}</Typography>

                <Typography pb={1}>{t("cookiesPara1")}</Typography>

                <Typography>{t("cookiesPara2")}</Typography>

                <Typography variant="h5" fontWeight="550" my={5}>{t("license")}</Typography>

                <Typography>{t("licensePara1")}</Typography>

                <Typography my={2}>{t("youMustNot:")}</Typography>
                <ul>
                    <li><Typography>{t("republishMaterial")}</Typography></li>
                    <li><Typography>{t("subLicenseMaterial")}</Typography></li>
                    <li><Typography>{t("reproduceMaterial")}</Typography></li>
                    <li><Typography>{t("redistributeContent")}</Typography></li>
                </ul>

                <Typography>{t("licensePara2")}<a target="_blank" href="https://www.termsfeed.com/terms-conditions-generator/" rel="noreferrer">{t("termsAndConditionGenerator")}</a>.</Typography>

                <Typography pb={1}>{t("licensePara3")}</Typography>

                <Typography pb={1}>{t("licensePara4")}</Typography>

                <Typography my={2}>{t("youWarrantAndRepresent")}</Typography>

                <ul>
                    <li><Typography>{t("youAreEntitledToPostTheComments")}</Typography></li>
                    <li><Typography>{t("theCommentsDoNotInvadeAnyIntellectualPropertyRight")}</Typography></li>
                    <li><Typography>{t("theCommentsDoNotInvadeAnyDefamatory")}</Typography></li>
                    <li><Typography>{t("theCommentsWillNotBeUsedToSolicit")}</Typography></li>
                </ul>

                <Typography>{t("licensePara5")}</Typography>

                <Typography variant="h5" fontWeight="550" my={5}>{t("hyperlinking")}</Typography>

                <Typography my={2}>{t('organisationsLinkWebsiteText')}</Typography>

                <ul>
                    <li><Typography>{t("governmentAgencies")}</Typography></li>
                    <li><Typography>{t("searchEngines")}</Typography></li>
                    <li><Typography>{t("newsOrganizations")}</Typography></li>
                    <li><Typography>{t("onlineDirectoryDistributors")}</Typography>
                    </li>
                    <li><Typography>{t("systemWideAccredited")}</Typography>
                    </li>
                </ul>

                <Typography my={2}>{t("theseOrganizationsMayLink")}</Typography>
                <Typography>{t("isNotInAnyWayDeceptive1")}.</Typography>

                <Typography my={2}>{t("weMayConsider")}</Typography>
                <ul>
                    <li><Typography>{t("commonlyKnown")}</Typography></li>
                    <li><Typography>{t("dotCom")}</Typography></li>
                    <li><Typography>{t("associations")}</Typography></li>
                    <li><Typography>{t("onlineDirectory")}</Typography></li>
                    <li><Typography>{t("internetPortals")}</Typography></li>
                    <li><Typography>{t("accounting")}</Typography></li>
                    <li><Typography>{t("educationalInstitutions")}</Typography></li>
                </ul>

                <Typography my={2}>{t("weWillApproveLink")}</Typography>
                <Typography>{t("theLinkWouldNotMake")}</Typography>

                <Typography my={2}>{t("theseOrganizations")}</Typography>
                <Typography>{t("isNotInAnyWayDeceptive2")}</Typography>

                <Typography>{t("ifYouParagraph2")}</Typography>

                <Typography my={2}>{t("approvedOrganizations")}</Typography>

                <ul>
                    <li><Typography>{t("corporateName")}</Typography></li>
                    <li><Typography>{t("uniformResourceLocator")}</Typography></li>
                    <li><Typography>{t("otherDescriptionOfOurWebsite")}</Typography>
                    </li>
                </ul>

                <Typography>{t("trademarkLicenseAgreement")}</Typography>

                <Typography variant="h5" fontWeight="550" my={5}>{t("iFrames")}</Typography>

                <Typography>{t("iFramesPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={5}>{t("contentLiability")}</Typography>

                <Typography>{t("contentLiabilityPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={5}>{t("reservationOfRights")}</Typography>

                <Typography>{t("reservationOfRightsPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={5}>{t("removalOfLinks")}</Typography>

                <Typography pb={1}>{t("removalOfLinksPara1")}</Typography>

                <Typography>{t("removalOfLinksPara2")}</Typography>

                <Typography variant="h5" fontWeight="550" my={5}>{t("disclaimer")}</Typography>

                <Typography my={2}>{t("toTheMaximumExtent")}</Typography>

                <ul>
                    <li><Typography>{t("limitLiabilityForDeath")}</Typography>
                    </li>
                    <li><Typography>{t("limitLiabilityForFraud")}</Typography></li>
                    <li><Typography>{t("limitLiabilities")}</Typography></li>
                    <li><Typography>{t("excludeLiabilities")}</Typography></li>
                </ul>

                <Typography my={2}>{t("theLimitationsAndLiability")}</Typography>
                <Typography>{t("areSubjectToThePrecedingParagraph")}</Typography>
                <Typography>{t("asLongAsTheWebsite")}</Typography>
            </Card>
        </Fragment>
    )
}

export default TermsOfService;