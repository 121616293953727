import { createSlice } from "@reduxjs/toolkit";

export let STATUS = {
    IDLE:'idle',
    LOADING:'Loading',
    ERROR:'error'
}

const IntegrationRequestSlice = createSlice({
    name: "IntegrationRequest",
    initialState:{
        IntegrationDetails: {},
        OrganizationDetails: {},
        stepActive: [{step: 0, status: 'completed'}, {step: 1, status: 'inprogress'},
            {step: 2, status: 'pending'}, {step: 3, status: 'pending'}],
        status: STATUS.IDLE,
    },

    reducers:{
        SetIntegrationDetails: (state, action) => {
            state.IntegrationDetails = action.payload
        },
        SetOrganizationDetails: (state, action) => {
            state.OrganizationDetails = action.payload;
        },
        SetActiveDetails: (state, action) => {
            state.stepActive = action.payload;
        },
        update: (state, action) => {
            const result = state.stepActive.map((step) => {
                if (step.step === Number(action.payload.name)) {
                    step.status = action.payload.value;
                    return step;
                } else {
                    return step;
                }
            })
            //To update next step in progress
            if (action.payload.value === 'completed') {
                const updatedResult = result.map((step) => {
                    if (step.step === (Number(action.payload.name) + 1) ) {
                        step.status = 'inprogress';
                        return step;
                    } else {
                        return step;
                    }
                })
                state.stepActive = updatedResult;
            } else {
                state.stepActive = result;
            }

        },
        ReSetIntegrationRequestState: (state) => {
            state.IntegrationDetails = {};
            state.OrganizationDetails = {};
        }
    }
});

export const {
    SetIntegrationDetails,
    SetOrganizationDetails,
    SetActiveDetails,
    update,
    ReSetIntegrationRequestState
} = IntegrationRequestSlice.actions;

export default IntegrationRequestSlice.reducer;