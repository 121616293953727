import { createSlice } from "@reduxjs/toolkit";


export let STATUS = {
    IDLE:'idle',
    LOADING:'Loading',
    ERROR:'error'
}

const teamMembersListSlice = createSlice({
    name: "TeamMembers list",
    initialState:{
        TeamMemberList : { teamMembers: [], totalCount:0, searchText: "", page: 1},
        status: STATUS.IDLE},
    reducers:{
        SetTeamMemberList: (state, action) => {
            state.TeamMemberList.totalCount = action.payload.totalCount;
            state.TeamMemberList.teamMembers = action.payload.teamMembers;
        },
        updateTeamMemberSearchTextPage: (state, action) => {
            state.TeamMemberList.searchText = action.payload.searchText;
            state.TeamMemberList.page = action.payload.page;
        },
        ResetTeamMemberList:(state, action)=>{
            state.TeamMemberList.teamMembers = [];
            state.TeamMemberList.teamMembers = 0;
            state.TeamMemberList.searchText = "";
            state.TeamMemberList.page = 1;
        }
    }

});

export const { SetTeamMemberList,updateTeamMemberSearchTextPage, ResetTeamMemberList } = teamMembersListSlice.actions;

export default teamMembersListSlice.reducer;
