import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { setSession } from "../utils/jwt";
import axios from "../utils/axios";
import {
    getTeamMemberNotifications,
    SetLoggedInTeamMember,
    SetOrgList
} from "../redux/slices/orgData";
import { useDispatch } from "react-redux";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SET_FLAG = "SET_FLAG";

let auth0Client = null;

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const reducer = (state, action) => {
    if (action.type === INITIALIZE) {
        const { isAuthenticated, user } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    }
    if (action.type === SIGN_IN) {
        const { user } = action.payload;
        return { ...state, isAuthenticated: true, user };
    }
    if (action.type === SIGN_OUT) {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
        };
    }

    if (action.type === SET_FLAG) {
        return {
            ...state,
            isAuthenticated: true,
            isInitialized: true
        };
    }
    return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const navigate = useNavigate();
    const dispatchThunkAction = useDispatch();

    useEffect(() => {
        initialize().catch(err => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const SetUserLoginDetails = async () => {
        try {
            const url = window.location.pathname;
            const response = await axios.get('admin/me');
            const Organization_data = response.data.data;
            const user = response.data.userData;
            await dispatchThunkAction(SetOrgList(Organization_data));
            await dispatchThunkAction(SetLoggedInTeamMember(user));
            dispatch({
                type: SIGN_IN,
                payload: { isAuthenticated: true, isInitialized: true, user: response.data?.userData || null },
            });
            if (!user || !user.email) return navigate('/login');
            if (url === '/login') {
                navigate("/dashboard");
            }
            await dispatchThunkAction(getTeamMemberNotifications());
        } catch (e) {
            await signOut();
        }
    }

    const initialize = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            if (accessToken) {
                await setSession(accessToken);
                await SetUserLoginDetails();
            }
        } catch (err) {
            dispatch({
                type: INITIALIZE,
                payload: { isAuthenticated: false, user: null },
            });
        }
    };

    const signIn = async () => {
        try {
            await SetUserLoginDetails();
        } catch (err) {
            dispatch({
                type: INITIALIZE,
                payload: { isAuthenticated: false, user: null },
            });
        }
    };

    const signUp = async () => {
        try {
            if (!auth0Client) await initialize();
            await auth0Client.loginWithRedirect({
                screen_hint: 'signup',
                redirect_uri: `${window.location.origin}/auth/sign-in`,
                redirectMethod: "replace"
            });
        } catch (error) {
            console.log(error.message)
            return error.response;
        }
    };

    const signOut = async () => {
        localStorage.removeItem("accessToken");
        navigate('/login');
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "auth0",
                user: {
                    id: state?.user?.sub,
                    avatar: state?.user?.picture,
                    email: state?.user?.email,
                    name: state?.user?.name,
                    displayName: state?.user?.nickname,
                    email_verified: state?.user?.email_verified ? state?.user?.email_verified : false,
                    phone_verified: state?.user?.phone_verified ? state?.user?.phone_verified : true,
                    role: state?.user?.role ? state?.user?.role : "Admin",
                },
                signIn,
                signOut,
                signUp
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
