import { createSlice } from "@reduxjs/toolkit";

const templateSlice = createSlice({
  name: "template",
  initialState: {
    template: [],
  },
  reducers: {
    updateTemplate: (state, action) => {
      state.template = action.payload;
    },
    addTemplate: (state, action) => {
      state.template = [...state.template, ...action.payload];
    },
  },
});

export const { updateTemplate, addTemplate } = templateSlice.actions;

export default templateSlice.reducer;
