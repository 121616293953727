import { createSlice } from "@reduxjs/toolkit";

export let STATUS = {
    IDLE:'idle',
    LOADING:'Loading',
    ERROR:'error'
}

const integrationsSlice = createSlice({
    name : "integration list",
    initialState:{
        integrationList : {integrations : [], totalCount : 0, searchText: "", page: 1, status: 'all' },
        integrationData: {},
        status: STATUS.IDLE,
    },

    reducers:{
        SetIntegrations: (state, action) => {
            state.integrationList.integrations = action.payload.integrations;
            state.integrationList.totalCount = action.payload.totalCount;
        },
        updateIntegrationSearchTextPage: (state, action) => {
            state.integrationList.searchText = action.payload.searchText;
            state.integrationList.status = action.payload.status;
            state.integrationList.page = action.payload.page;
        },
        SetIntegrationDetails: (state, action) => {
            state.integrationData = action.payload;
        },
        SetIntegrationName: (state, action) => {
            state.integrationData = action.payload;
        },
        ResetIntegrationDetails: (state, action) => {
            state.integrationData = {};
            state.integrationList = {integrations : [], totalCount : 0, searchText: "", page: 1 };
            state.status = STATUS.IDLE

        }
    }
})

export const { SetIntegrations,updateIntegrationSearchTextPage,SetIntegrationDetails, SetIntegrationName, ResetIntegrationDetails } = integrationsSlice.actions;

export default integrationsSlice.reducer;