import { configureStore } from "@reduxjs/toolkit";
import organizationListSlice from "./slices/OrganizationList";
import templateListSlice from "./slices/TemplateList";
import teamMembersListSlice from "./slices/TeamMemberList";
import teamMemberDataSlice from "./slices/TeamMemberData";
import orgDataSlice from "./slices/orgData";
import botListSlice from "./slices/BotList.js";
import bsaReqSlice from "./slices/bsaReqList.js";
import bsaAcCredentialSlice from "./slices/bsaAcCredentialSlice.js";
import companyDetailsSlice from "./slices/companyDetailsSlice.js";
import bsaAcDetailsSlice from "./slices/bsaAcDetailsSlice.js";
import integrationsSlice from "./slices/integrationsSlice.js";
import IntegrationRequestSlice from "./slices/IntegrationRequestSlice.js";
import CustomersSlice from "./slices/CustomersSlice.js";
import ActivitySlice from "./slices/ActivityLogs";
import smsTemplateSlice from "./slices/smsTemplateSlice.js";

export const store = configureStore({
    reducer: {
        Organization_List: organizationListSlice,
        templates_list: templateListSlice,

    TeamMembers_List: teamMembersListSlice,
    Bots_List: botListSlice,

    TeamMember: teamMemberDataSlice,
    org: orgDataSlice,

    BusinessAcReqs: bsaReqSlice,
    CompDetails: companyDetailsSlice,
    BusinessAcCredential: bsaAcCredentialSlice,
    BusinessAcDetails: bsaAcDetailsSlice,

    integrations: integrationsSlice,
    IntegrationRequest: IntegrationRequestSlice,

    Customers: CustomersSlice,

    activityFeed: ActivitySlice,

    templateSlice: smsTemplateSlice,
  },
  devTools: true,
});
