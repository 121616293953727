import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import useTheme from "../../hooks/useTheme";
import {ListItemButton, ListItemText} from "@mui/material";
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone';
import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import styled from "@emotion/styled";


const Item = styled(ListItemButton)`
  border-left: 3px solid transparent;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-inline: 8px;
  margin-block: 8px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  display: flex;
  flex-direction: column;
  padding-left: 0px !important;
  padding-right: 0px !important;


  svg {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 19px;
    width: 25px;
    height: 25px;
    opacity: 1;
  }

  &:hover {
    margin-inline: 8px;
    margin-block: 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:active {
    margin-inline: 8px;
    margin-block: 8px;
    border-radius: 8px;
    color: ${(props) => props.theme.palette.primary.main};
    background-color: rgba(112, 179, 255, 0.4);

    span {
      color: ${(props) => props.theme.palette.primary.main};;
    }
  }
`;
const Title = styled(ListItemText)`
  span {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: ${(props) => props.theme.typography.caption1.fontSize};
    padding: 0 ${(props) => props.theme.spacing(0)};
  }
`;
export default function DarkModeSwitch() {
    const {theme, setTheme} = useTheme();

    return (
        <FormGroup>
            <Item checked={theme === 'DARK'}
                  onClick={() => {
                      setTheme(theme !== "DARK" ? 'DARK' : 'DEFAULT');
                  }}
                  inputprops={{'aria-label': 'controlled'}}
            >
                {theme === 'DARK' ? <DarkModeTwoToneIcon /> : <LightModeTwoToneIcon/>}
                <Title>Switch Mode</Title>
            </Item>
        </FormGroup>
    );
}
