export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  redirectUrl: process.env.REACT_APP_AUTH_REDIRECT_URL
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export const appConfig = {
  appServerUrl: process.env.REACT_APP_SERVER_URL,
  appNotificationServerUrl: process.env.REACT_APP_NOTIFICATION_SERVER_URL,
  subscriptionServiceUrl: process.env.REACT_APP_SUBSCRIPTION_URL,
  baseWebhookServerURL: process.env.REACT_APP_WEBHOOK_SERVER_URL
}

export const EWhatsAppAPIVersion = {
  "v1": 'partners_v1',
  "v3": 'partners_v3'
}