import { createSlice } from "@reduxjs/toolkit";

export let STATUS = {
    IDLE:'idle',
    LOADING:'Loading',
    ERROR:'error'
}

const templateListSlice = createSlice({
    name: "template list",
    initialState:{
        TemplateList : { templates: [], totalCount:0, searchText: "", page: 1},
        BotTemplateData : {},
        status: STATUS.IDLE},
    reducers:{
        SetBotTemplateList: (state, action) => {
            state.TemplateList.totalCount = action.payload.totalCount;
            state.TemplateList.templates = action.payload.templates;
        },
        SetBotTemplate: (state, action) => {
            state.BotTemplateData = action.payload;
        },
        updateBotTemplateSearchTextPage: (state, action) => {
            state.TemplateList.searchText = action.payload.searchText;
            state.TemplateList.page = action.payload.page;
        },
        SetBotTemplateName: (state, action) => {
            state.BotTemplateData = action.payload;
        },
        DeleteBotTemplate: (state, action) => {
            state.TemplateList.templates = state.TemplateList.templates.filter(template => template._id !== action.payload);
            state.TemplateList.totalCount = state.TemplateList.totalCount > 1 ? state.TemplateList.totalCount - 1 : 0;
        },
        ResetState: (state) =>{
            state.BotTemplateData = {};
            state.TemplateList = { templates: [], totalCount:0, searchText: "", page: 1};
            state.status = STATUS.IDLE
        }
    },
});

export const { SetBotTemplateList,  SetBotTemplate, SetBotTemplateName, ResetState, DeleteBotTemplate,updateBotTemplateSearchTextPage } = templateListSlice.actions;

export default templateListSlice.reducer;

