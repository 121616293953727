import axios from "axios";
import {appConfig} from "../config";

const axiosInstance = axios.create({
    baseURL:appConfig.appServerUrl
});
const accessToken = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImVWZVF3eV9sUW5Wdk9MZTZrTmJzcyJ9.eyJuaWNrbmFtZSI6InVkZGVzaHRoYWthcmUiLCJuYW1lIjoidWRkZXNodGhha2FyZUB5YWhvby5jb20iLCJwaWN0dXJlIjoiaHR0cHM6Ly9zLmdyYXZhdGFyLmNvbS9hdmF0YXIvNzg3ZmM1YjM4ODA5MmYzOGEzZjk3N2VlNDMzMzk5NDI_cz00ODAmcj1wZyZkPWh0dHBzJTNBJTJGJTJGY2RuLmF1dGgwLmNvbSUyRmF2YXRhcnMlMkZ1ZC5wbmciLCJ1cGRhdGVkX2F0IjoiMjAyMi0wNy0yNlQxMjo1MjoxNi45MzFaIiwiZW1haWwiOiJ1ZGRlc2h0aGFrYXJlQHlhaG9vLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiaXNzIjoiaHR0cHM6Ly9kZXYtMXNwb2MudXMuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDYyZDUxOGZkMjRmMzJhNDE4NWNhMjk0MCIsImF1ZCI6IlNvM281OXZVWHQ4cmZzRTUzOGQycDA4VDMxbmM5bkRnIiwiaWF0IjoxNjU4OTAxNjk2LCJleHAiOjE2NTg5Mzc2OTYsImFjciI6Imh0dHA6Ly9zY2hlbWFzLm9wZW5pZC5uZXQvcGFwZS9wb2xpY2llcy8yMDA3LzA2L211bHRpLWZhY3RvciIsImFtciI6WyJtZmEiXSwibm9uY2UiOiJaVkJtUWtoWmJYUitNa3hTYjI0ek9YSjJXWEIzVXpCR2RDNXBVbE5pUkVKdVl6VkViMnRMTVc5elh3PT0ifQ.Bcxg8B_sqMO3-ouTyMr7YaRVWXXhQEblDJ578bli3dWVEpEJAk-MNFzUH12r0QBI9el0DAf02m5P0gIFKnqyTuK_P9SMdObIWUg2bCy7uIpyfogrr05m_BZTecKXyo5MrwIbPK8wFbk9ZxZIHELvOhSmNNmDC55rRY6N1AMvDOYsqFMw_644q7aiPeflu-sVkXwJ2tSMRVo2yMH9LQ6SCQePfi7VKZc9BK5rVtScd-dP9mXp3572aHoieGrq0hWdMX0AuuSesXWTdilqI0VYJDohlCBeZNKz0DGj5va2J4NGuk-_Ek9aQhtyaaa_pQsySo47EG9-ddubM0XSsihrxw'
axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
