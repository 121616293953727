import {createSlice} from "@reduxjs/toolkit";

let companyData =[
    {
        id: '04353454354310',
        company_name: '1spoc',
        industry: 'Internet',
        gst_number: '123456789012345',
        country: "India",
        city: 'Nagpur',
        company_website: 'thinkonic.com',
        company_size: '1-9',
        mobile_number: '+919890609684',
        state: 'Maharashtra',
        pin_code: "440016",
    },
    {
        id: '1543454354310',
        company_name: 'Pinnacle',
        industry: 'Finance',
        gst_number: '123456789012345',
        country: "India",
        city: 'Nagpur',
        company_website: 'pinnacle.com',
        company_size: '1-9',
        mobile_number: '+919890609684',
        state: 'Maharashtra',
        pin_code: "440016",
    },
    {
        id: '0534221344210',
        company_name: '1spoc',
        industry: 'Internet',
        gst_number: '123456789012345',
        country: "India",
        city: 'Hyderbad',
        company_website: 'thinkonic.com',
        company_size: '1-9',
        mobile_number: '+919320609684',
        state: 'Maharashtra',
        pin_code: "440016",
    },
    {
        id: '01042242231312',
        company_name: 'Amazon',
        industry: 'Internet',
        gst_number: '123456789012345',
        country: "India",
        city: 'Indore',
        company_website: 'amazon.com',
        company_size: '1-9',
        mobile_number: '+919890609684',
        state: 'Madhya Pradesh',
        pin_code: "440016",
    },
    {
        id: '0242342423410',
        company_name: 'Facebook',
        industry: 'Internet',
        gst_number: '123456789012345',
        country: "India",
        city: 'Nagpur',
        company_website: 'fb.com',
        company_size: '1-9',
        mobile_number: '+919890609684',
        state: 'Maharashtra',
        pin_code: "440016",
    },
]


export let STATUS = {
    IDLE:'idle',
    LOADING:'Loading',
    ERROR:'error'
}

const bsaReqSlice = createSlice({
    name: "account credential",
    initialState:{
        creadentails : companyData,
        details: {},
        status: STATUS.IDLE,
    },

    reducers:{
        SetDetails: (state, action) => {
            state.creadentails = action.payload
        },
        SetCompDetail: (state, action) => {
            state.details = action.payload;
        },
        ResetCompDetail: (state, action) => {
            state.details = {};
        },
    }
});

export const { SetCredentials, SetCompDetail, ResetCompDetail} = bsaReqSlice.actions;

export default bsaReqSlice.reducer;