import { createSlice } from "@reduxjs/toolkit";

export let STATUS = {
    IDLE:'idle',
    LOADING:'Loading',
    ERROR:'error'
}

const bsaReqSlice = createSlice({
    name: "request list",
    initialState:{
        requests :{orgIntegrations:[],  totalCount:0, searchText: "", page: 1},
        reqDetails: {},
        status: STATUS.IDLE,
    },

    reducers:{
        SetReqList: (state, action) => {
            state.requests.totalCount = action.payload.totalCount;
            state.requests.orgIntegrations = action.payload.orgIntegrations;
        },
        updateRequestSearchTextPage: (state, action) => {
            state.requests.searchText = action.payload.searchText;
            state.requests.page = action.payload.page;
        },
        SetReqDetails: (state, action) => {
            state.reqDetails = action.payload;
        },
        ResetRequestState: (state) =>{
            state.reqDetails = {};
            state.requests = { templates: [], totalCount:0, searchText: "", page: 1};
            state.status = STATUS.IDLE
        }
    }
});

export const { SetReqList, SetReqDetails,ResetRequestState,updateRequestSearchTextPage } = bsaReqSlice.actions;

export default bsaReqSlice.reducer;