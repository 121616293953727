import { createSlice } from "@reduxjs/toolkit";


export let STATUS = {
    IDLE:'idle',
    LOADING:'Loading',
    ERROR:'error'
}

const teamMemberDataSlice = createSlice({
    name: 'TeamMember',
    initialState: {
        data : {},
        ownersCount: 0,
        status : STATUS.IDLE
    },
    reducers:{
        SetTeamMemberData: (state, action) => {
            state.data = action.payload.data;
            state.ownersCount = action.payload.ownersCount;
        },
        DeleteTeamMemberData: (state, action) => {
            state.data = state.data.filter(teamMember => teamMember._id !== action.payload);
            state.data = '';
        },
        ResetState: (state, action) => {
            state.data = {};
            state.status = STATUS.IDLE
        },
    },
})

export const { SetTeamMemberData, DeleteTeamMemberData, ResetState } = teamMemberDataSlice.actions;

export default teamMemberDataSlice.reducer;
