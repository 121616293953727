import React, {forwardRef, useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";
import {NavLink, useNavigate} from "react-router-dom";
import {rgba} from "polished";

import {Chip, Collapse, List, ListItemButton, ListItemText, Menu, Typography} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTranslation} from "react-i18next";

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <NavLink {...props} />
    </div>
));

const Item = styled(ListItemButton)`
  border-left: 3px solid transparent;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-inline: 16px;
  margin-block: 8px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  display: flex;
  flex-direction: column;
  position: relative;

  svg {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 25px;
    width: 25px;
    height: 25px;
    opacity: 1;
  }

  &:hover {
    margin-inline: 16px;
    margin-block: 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.palette.primary.main};
  }

  &.${(props) => props.activeclassname} {
    margin-inline: 16px;
    margin-block: 8px;
    border-radius: 8px;
    color: ${(props) => props.theme.palette.primary.main};
    background-color: rgba(112, 179, 255, 0.2);

    span {
      color: ${(props) => props.theme.palette.primary.main};;
    }
  }
`;

const Title = styled(ListItemText)`
  margin: 4px;

  span {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: ${(props) => props.theme.typography.caption1.fontSize};
    padding: 0 ${(props) => props.theme.spacing(0)};
  }
`;


//disable sidebar options
const ItemDisabled = styled(ListItemButton)`
  padding-top: ${(props) =>
          props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
          props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
          props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
          props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &.${(props) => props.activeclassname} {
    pointer-events: none !important;
  }
`;
const TitleDisabled = styled(ListItemText)`
  margin: 0;

  span {
    color: #9e9e9e;
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;
const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(0)};
    padding-right: ${(props) => props.theme.spacing(0)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const SidebarNavListItem = (props) => {
    const {
        title,
        href,
        depth = 0,
        children,
        icon,
        badge,
        open
    } = props;

    const currentRoute = window.location.pathname;
    const {t} = useTranslation('global');
    const theme = useTheme();
    let isLgUp = useMediaQuery(theme.breakpoints.up("xl"));
    const settingsIconAnchor = useRef(null);
    const [isReportPopupOpen, setIsReportPopupOpen] = useState(false);

    useEffect(() => {
        if (open && !isopen) {
            return setOpen(open);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRoute]);


    const [isopen, setOpen] = React.useState(open);
    const navigate = useNavigate();
    const {signOut} = useAuth();

    const handleToggle = () => {
        setOpen((state) => !state);
    };
    const handleClickMenu = async (e, url, title) => {
        e.preventDefault();
        if (url === '/auth/sign-out') {
            return signOut();
        }
        if (title !== 'Reports') {
            return navigate(url);
        }

    };
    const popoverEnter = (title) => {
        if (title === 'Reports') {
            setIsReportPopupOpen(true);
        }
    };

    const popoverLeave = (title) => {
        if (title === 'Reports') {
            setIsReportPopupOpen(false);
        }
    };

    if (children) {
        return (
            <React.Fragment>
                <Item depth={depth} onClick={handleToggle}>
                    {icon}
                    {/*<span className={icon} style={{fontSize: '32px'}}/>*/}
                    <Title depth={depth}>
                        {title}
                        {badge && <Badge label={badge}/>}
                    </Title>
                    {isopen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </Item>
                <Collapse in={isopen}>{children}</Collapse>
            </React.Fragment>
        );
    }
    if (!href || href === '') {
        return (
            <React.Fragment>
                <ItemDisabled depth={depth}>
                    {/*<span className={icon} style={{fontSize: '32px'}}/>*/}
                    {icon}
                    <TitleDisabled depth={depth}>
                        {title}
                        {badge && <Badge label={badge}/>}
                    </TitleDisabled>
                </ItemDisabled>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <Item
                    depth={depth}
                    component={CustomRouterLink}
                    to={href}
                    activeclassname="active"
                    onClick={(e) => handleClickMenu(e, href, title)}
                    ref={settingsIconAnchor}
                    aria-owns={isReportPopupOpen ? 'settings-over-popover' : null}
                    aria-haspopup="true"
                    onMouseEnter={() => popoverEnter(title)}
                    onMouseLeave={() => popoverLeave(title)}
                >
                    {icon}
                    <Title depth={depth}>
                        {title}
                        {badge && <Badge label={badge}/>}
                    </Title>
                </Item>


                {/*Report Logs menu options*/}
                <Menu
                    id="settings-over-popover"
                    open={isReportPopupOpen}
                    anchorEl={settingsIconAnchor.current}
                    onClose={() => popoverLeave('Reports')}
                    PaperProps={{
                        style: {
                            pointerEvents: 'auto',
                            boxShadow: 'rgb(0 0 0 / 5%) 5px 4px 4px 2px',
                            borderRadius: '16px',
                            marginLeft: "0.5%",
                            width: isLgUp ? "12rem" : "15rem"
                        }
                    }}
                    anchorOrigin={{vertical: 0, horizontal: 'right'}}
                    transformOrigin={{vertical: 0, horizontal: 'left'}}
                    MenuListProps={{
                        onMouseEnter: () => popoverEnter('Reports'),
                        onMouseLeave: () => popoverLeave('Reports')
                    }}
                    sx={{pointerEvents: 'none'}}
                >
                    <List sx={{padding: 0}}>
                        <ListItemButton onClick={() => (navigate('/activity/logs'))} className='pt-2' dense>
                            <ListItemText
                                disableTypography
                                primary={<Typography variant="body1" sx={{
                                    color: currentRoute === '/activity/logs' ? '#1180FF' : ''
                                }}>{t("logs")}</Typography>}/>
                        </ListItemButton>
                        <ListItemButton onClick={() => (navigate('/activity/customers-logs'))}
                                        className="square pb-2" dense>
                            <ListItemText
                                disableTypography
                                primary={<Typography variant="body1" sx={{
                                    color: currentRoute === '/activity/customers-logs' ? '#1180FF' : ''
                                }}>{t("customersLogs")}</Typography>}/>
                        </ListItemButton>
                    </List>
                </Menu>
            </React.Fragment>
        );
    }

};

export default SidebarNavListItem;
