import {css, Global} from "@emotion/react";

const GlobalStyle = (props) => (
    <Global
        {...props}
        styles={css`
          html,
          body,
          #root {
            height: 100%;
          }

          body {
            margin: 0;
            //font-family: 'Poppins' !important;
          }

          p {
            margin-bottom: 0 !important;
          }

          .font-theme {
            font-family: Poppins, Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
          }

          //sidebar fonts
          //.css-1tdh1ss-MuiListItemText-root span{font-size: 20px !important;}
          //sidebar icon
          .css-8laq16-MuiButtonBase-root-MuiListItemButton-root svg {
            width: 25px;
            height: 25px;
            font-weight: bold;
          }

          .MuiCardHeader-action .MuiIconButton-root {
            padding: 4px;
            width: 28px;
            height: 28px;
          }

          body > iframe {
            pointer-events: none;
          }

          .cursor-pointer {
            cursor: pointer !important;
          }

          Button {
            font-size: 16px !important;
          }

          .btn-theme {
            min-width: 100px !important;
            font-size: 16px !important;
          }

          .btn-hover {
            &:hover {
              background-color: #3949ab !important;
              color: white !important;
            }
          }

          //input label
          .css-n482vb-MuiFormLabel-root-MuiInputLabel-root {
            font-size: 16px;
            font-weight: 600;
          }

          //input label required field
          .css-8zu7nu-MuiFormHelperText-root {
            font-size: 16px;
          }

          //scrollbar hide class
          .scrollBarHide {
            ::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
              background-color: transparent;
              border-radius: 10px;
            }

            ::-webkit-scrollbar {
              width: 6px;
              background-color: transparent;
            }

            ::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: #7A7A7A5F;
            }

            ::-webkit-scrollbar-button {
              height: 30px;
            }
          }

          //scrollbar styling
          ::-webkit-scrollbar {
            width: 6px;
            background-color: transparent;
          }

          ::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background-color: #7A7A7A5F;
          }

          //mui swtich toggler
          .css-julti5-MuiSwitch-root {
            width: 65px !important;
            height: 40px !important;
          }

          .css-1duvwh6-MuiTypography-root {
            font-size: 16px !important;
            display: none !important;
          }

          .css-1h8vv17-MuiSwitch-thumb {
            width: 22px !important;
            height: 22px !important;
          }

          //hide label
          .css-w3yu8-MuiTypography-root {
            display: none !important;
          }

          //phone no container 
          .react-tel-input.contact-number-input.invalid input[type='tel'] {
            border: 1px solid #ce0b0b !important;

            &:focus {
              border: 1px solid #ce0b0b !important;
            }
          }

          .react-tel-input.contact-number-input.invalid .selected-flag {
            border: 1px solid #ce0b0b !important;

            &:focus {
              border: 1px solid #ce0b0b !important;
            }
          }

          .react-tel-input .flag-dropdown.open-dropdown {
            border-radius: 8px 0 0 8px !important;
          }

          .react-tel-input .flag-dropdown.open-dropdown .selected-flag {
            border-radius: 8px 0 0 8px !important;
          }

          .card-div {
            background-color: white !important;
            border-radius: 4px !important;
          }

          //icon color classes
          .icon-color-white:before {
            color: #fff !important;
          }

          .icon-color-dark:before {
            color: #000 !important;
          }

          .icon-color-primary:before {
            color: #407ad6 !important;
          }

          .react-select-container {
            width: 300px !important;
          }

        `}
    />
);

export default GlobalStyle;
