import React from "react";
import {useNavigate} from "react-router-dom";
import {
    Avatar,
    Box,
    Card as MuiCard,
    Grid, IconButton, Stack, Typography
} from "@mui/material";
import styled from "@emotion/styled";
import {spacing} from "@mui/system";
import {useTheme} from "@mui/material/styles";

const Card = styled(MuiCard)(spacing);

const Ellipsis = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  font-family: inherit;
  width: 340px;
`

const Header = ({redirect, heading="", subHeading="", icon="",  sideContent=""}) => {
    const theme = useTheme();
    const iconColor = theme.name === 'DARK' ? 'white' : '';
    const navigate = useNavigate();

    const onHandleClick =()=> {
        if(!redirect) return;
        navigate(redirect);
    }
    return <Card p={5}>
        <Grid container display="flex" alignItems="center" justifyContent="space-between">
            <Grid item display="flex" alignItems="center" justifyContent="space-between">
                <Grid mr={2}>
                    {redirect && <IconButton onClick={onHandleClick} color="inherit" >
                        <Box className={`icon-chevron icon-color-${iconColor}`} style={{fontSize: '24px', transform: 'rotate(90deg)'}}/>
                    </IconButton>}
                </Grid>
                <Grid display="flex" alignItems="center" >
                    {icon && <Avatar variant="square" src={icon} alt={heading} sx={{marginX: '1rem'}} />}
                    <Stack>
                        <Typography variant="h4" ><Ellipsis>{heading}</Ellipsis></Typography>
                        {subHeading && <Typography variant="body2" className="mt-1" color="textSecondary">{subHeading}</Typography>}
                    </Stack>
                </Grid>
            </Grid>
            { sideContent && sideContent }
        </Grid>
    </Card>
}

export default Header