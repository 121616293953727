import React, {Fragment} from "react";
import {Helmet} from "react-helmet-async";
import {Card,Typography} from "@mui/material";
import Header from "../components/Header";
import {useTranslation} from "react-i18next";
import Loader from "../../../components/Loader"


const PrivacyPage = () => {
    const {t,ready} = useTranslation('privacy');

    if (!ready) return <Loader/>;
    return (
        <Fragment>
            <Helmet title="Privacy"/>
            <Header heading={t("privacyPolicy")}/>
            <Card sx={{marginTop: '1rem', padding: '2.5rem'}}>
                <Typography>{t("introduction")}</Typography>

                <Typography my={2}>{t("thisPrivacyPolicy")}</Typography>
                <ul>
                    <li><Typography>{t("whatInformation")}</Typography></li>
                    <li><Typography>{t("howWeCollect")}</Typography></li>
                    <li><Typography>{t("whyWeCollect")}</Typography></li>
                    <li><Typography>{t("howKeepSecure")}</Typography></li>
                    <li><Typography>{t("howYouUpdate")}</Typography></li>
                </ul>

                <Typography variant="h5" fontWeight="550" my={4}>{t("purpose")}</Typography>
                <Typography>{t("purposePara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("applicability")}</Typography>
                <Typography>{t("applicabilityPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("infoWeProcess")}</Typography>
                <ol type="a">
                    <li><Typography pb={1}>{t("weUseYourPersonalInfo")}</Typography></li>
                    <li><Typography>{t("byUsingTheService")}</Typography></li>
                </ol>

                <Typography my={2}>{t("weBuildRangeOfService")}</Typography>
                <ul>
                    <li><Typography>{t("applications")}</Typography></li>
                    <li><Typography>{t("webForms")}</Typography></li>
                    <li><Typography>{t("offSiteServices")}</Typography></li>
                    <li><Typography>{t("others")}</Typography></li>
                    <li><Typography>{t("contractualCommitments")}</Typography></li>
                </ul>

                <Typography variant="h5" fontWeight="550" my={4}>{t("infoWeCollect")}</Typography>
                <Typography my={2}>{t("weCollectInfo")}</Typography>
                <ul>
                    <li><Typography>{t("phoneNumbers")}</Typography></li>
                    <li><Typography>{t("emailAddresses")}</Typography></li>
                    <li><Typography>{t("userName")}</Typography></li>
                    <li><Typography>{t("billingAddresses")}</Typography></li>
                </ul>

                <Typography variant="h5" fontWeight="550" my={4}>{t("logData")}</Typography>
                <Typography>{t("whenWeUseOurSite")}</Typography>
                <Typography my={2}>{t("thisMayInclude")}</Typography>
                <ul>
                    <li><Typography>{t("details")}</Typography></li>
                    <li><Typography>{t("internetProtocol")}</Typography></li>
                    <li><Typography>{t("deviceEventInfo")}</Typography></li>
                    <li><Typography>{t("cookiesIdentify")}</Typography></li>
                    <ul>
                        <li><Typography>{t("yourComputersInternetProtocol")}</Typography></li>
                        <li><Typography>{t("inAddition")}</Typography></li>
                    </ul>
                </ul>

                <Typography variant="h5" fontWeight="550" my={4}>{t("locationInfo")}</Typography>
                <Typography>{t("locationPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("cookies")}</Typography>
                <Typography>{t("cookiesPara")}</Typography>
                <Typography my={2}>{t("instructions")}</Typography>
                <ul>
                    <li><Typography>{t("chrome")}</Typography></li>
                    <li><Typography>{t("firefox")}</Typography></li>
                    <li><Typography>{t("internetExplorer")}</Typography></li>
                    <li><Typography>{t("opera")}</Typography></li>
                    <li><Typography>{t("safari")}</Typography></li>
                    <li><Typography>{t("however")}</Typography></li>
                </ul>

                <Typography variant="h5" fontWeight="550" my={4}>{t("serviceProvider")}</Typography>
                <Typography>{t("serviceProviderPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("thirdParty")}</Typography>
                <Typography pb={1}>{t("thirdPartyPara1")}</Typography>
                <Typography pb={1}>{t("thirdPartyPara2")}</Typography>
                <Typography>{t("thirdPartyPara3")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("kidsPrivacy")}</Typography>
                <Typography>{t("kidsPrivacyPara")}</Typography>


                <Typography variant="h5" fontWeight="550" my={4}>{t("communication")}</Typography>
                <Typography>{t("communicationPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("howProtectInfo")}</Typography>
                <Typography>{t("howProtectInfoPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("complianceWithLaw")}</Typography>
                <Typography>{t("complianceWithLawPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("security")}</Typography>
                <Typography>{t("securityPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("internationalTransfer")}</Typography>
                <Typography pb={1}>{t("internationalTransferPara1")}</Typography>
                <Typography pb={1}>{t("internationalTransferPara2")}</Typography>
                <Typography>{t("internationalTransferPara3")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("linksToOtherSites")}</Typography>
                <Typography>{t("linksToOtherSitesPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("groundsForProcessingData")}</Typography>
                <Typography>{t("groundsForProcessingDataPara")}</Typography>
                <ul>
                    <li><Typography>{t("whenWeProcessCookies")}</Typography></li>
                    <li><Typography>{t("whenWeProcessPersonalData")}</Typography></li>
                    <li><Typography>{t("performance")}</Typography></li>
                    <li><Typography>{t("consent")}</Typography></li>
                    <li><Typography>{t("fraudPrevention")}</Typography></li>
                    <li><Typography>{t("performance")}</Typography></li>
                    <li><Typography>{t("detect")}</Typography></li>
                    <li><Typography>{t("Protect")}</Typography></li>
                </ul>

                <Typography variant="h5" fontWeight="550" my={4}>{t("changesToPrivacyPolicy")}</Typography>
                <Typography>{t("changesToPrivacyPolicyPara")}</Typography>

                <Typography variant="h5" fontWeight="550" my={4}>{t("contactUs")}</Typography>
                <Typography>{t("contactUsPara")}</Typography>

                <Typography variant="h6" fontWeight="550" my={4}>{t("contactSupport")}</Typography>
                <Typography>{t("telephone")}+91 83088 00004, +91 83088 00005</Typography>
                <Typography >{t("emailId")}<a href="mailto:support@1SPOC.ai" rel="support">support@1SPOC.ai</a></Typography>

            </Card>
        </Fragment>
    )
}

export default PrivacyPage;