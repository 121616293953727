

const components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiCardHeader: {
    defaultProps: {
      fontSize: "1.25rem",
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiButton:{
    styleOverrides:{
      root:{
        borderRadius: "8px",
      },
    },
  },
  MuiTextField:{
    styleOverrides:{
      root:{
        "& .MuiInputBase-root": {
          borderRadius: "8px",
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
        borderRadius: "16px",
        boxShadow:
          "0px 3px 6px #00000029",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        "& .MuiDialog-paper":{
          borderRadius: "16px"
        },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiTableHead:{
    styleOverrides: {
      root: {
        fontWeight: 600,
        "& .MuiTableCell-head": {
            fontSize: "15px",
            fontWeight: 700 ,
            //backgroundColor: "#E5E8EC",
          "&:first-of-type":{
            paddingLeft: "2.5rem !important",
          },
        },
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        "& .MuiTableBody-root": {
          "& .MuiTableRow-root:last-child": {
            "& .MuiTableCell-root":{
              border: "0 !important",
            }
          },
        }
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderRadius: "16px",
      },
    },
  },
  MuiTableRow:{
    styleOverrides: {
      root: {
        height: "40px",
        "& .MuiTableCell-body": {
          "&:first-child": {
            paddingLeft: "2.5rem !important",
          },
        },
      },
    },
  },
  MuiTableCell:{
    styleOverrides: {
      root: {
        fontSize: "13px",
        fontWeight: 400,
        padding:"8px",
      },
    },
  },
  MuiAvatar : {
    styleOverrides: {
      root: {
        fontSize: "13px",
        fontWeight: 400,
      },
    },
  },
  MuiPagination : {
    styleOverrides: {
      root: {
        fontSize: "1rem",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
  MuiMenuItem : {
    styleOverrides: {
      root: {
        paddingLeft: '50px',
        paddingRight: '50px',
        fontSize: '16px'
      },
    },
  }
  // MuiBox:{
  //   styleOverrides:{
  //     root:{
  //       marginLeft: "12px",
  //       fontSize: "18px",
  //     },
  //   },
  // },
};

export default components;
