import React, { useState } from "react";
import styled from "@emotion/styled";
import { Avatar as MuiAvatar, Drawer as MuiDrawer, ListItemButton } from "@mui/material";
import SidebarNav from "./SidebarNav";
import { useTheme } from "@mui/material/styles";
import NotificationsReport from "../../pages/pages/Notifications";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 80px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 80px;
  }


`;

const Avatar = styled(MuiAvatar)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const SmallAvatar = styled(Avatar)`
  height: auto;
`;

const Sidebar = ({ items, ...rest }) => {
  const theme = useTheme();
  const [isOpen, setIsOen] = useState(false);

  const openNotification = async () => {
    await setIsOen(!isOpen);
  };


  return (
    <>
      <Drawer variant="permanent" {...rest} PaperProps={{
        sx: {
          width: "7rem",
          "&::-webkit-scrollbar": {
            width: 3,
          },
          "&::-webkit-scrollbar-thumb": {
            width: 3,
            backgroundColor: 'transparent'
          },
          "&:hover": {
            "&::-webkit-scrollbar-thumb": {
              width: 3,
              backgroundColor: '#7A7A7A5F'
            },
          }
        }
      }}>
        <Brand>
          <SmallAvatar variant="square" alt="Organization Logo"
            src={theme.name === 'DARK' ? "/static/img/logo-light.svg" : "/static/img/logo-dark1.svg"}
            className="mt-3 mb-2 mr-1" />
        </Brand>
        <SidebarNav items={items} openNotification={openNotification} />
      </Drawer>
      <NotificationsReport openNotification={openNotification} isOpen={isOpen} />
    </>
  );
};

export default Sidebar;
